import { useEffect, useState } from "react"
import { getCartUpsells } from "../../scripts/fetchApi"
import { useSelector } from "react-redux"
import UpsellsItem from "./UpsellsItem"

import '@splidejs/react-splide/css';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import classes from './CartUpsells.module.css'
import ArrowSliderLeftIcon from "../../icons/arrowsliderleft";
import ArrowSliderRightIcon from "../../icons/arrowsliderright";
import { setStorageItem } from "../../scripts/localStorage";


const CartUpsells = ({cartId}) => {
    const [upsells, setUpsells] = useState(null)
    const [loading, setLoading] = useState(false)
    const [hasPath, setHasPath] = useState(false)
    let hasLocation = useSelector(state => state.location.location)

    useEffect(() => {
        const getUpsells = async () => {
            setLoading(true)
            if (hasLocation.id) {
                const items = await getCartUpsells(cartId, hasLocation.id)

                if (items && items.length > 0) {
                    setStorageItem('cartUpsells', items)
                }
                let path = items.find(item => item.imagepath)
                if (path) {
                    setHasPath(path.imagepath)
                }
                setUpsells(items)                
            }    
            setLoading(false)
        }    
        getUpsells()
    }, [cartId, hasLocation])    

    return <div className={classes.upsells_container}>
        {loading && <div className="text-center"><span className="loader dark"></span></div>}
        {!loading && <>
            <h5 className={classes.upsells_title}>Don't Forget!</h5>
            {upsells && upsells.length > 0 && <>
                {upsells.map(upsellCategory => (
                    <div key={upsellCategory.title} className={classes.upsells_category_item}>
                        <div className={classes.upsells_category_title}>{upsellCategory.title}</div>
                        {upsellCategory.items && upsellCategory.items.length > 0 && <div className="card_alt_items">

                        <Splide className='splide-custom-arrows' aria-label="Rewards" hasTrack={ false } options={ {
                            rewind: false,
                            perPage: 2,
                            pagination: false,
                            gap   : '1rem',
                            arrows: true,
                        } }>
                            <SplideTrack>
                                {upsellCategory.items.map(upsell => (
                                    <SplideSlide key={upsell.id}>
                                        <UpsellsItem item={upsell} imgPath={upsellCategory.imagepath ? upsellCategory.imagepath : hasPath}/>
                                    </SplideSlide>                                    
                                ))}
                            </SplideTrack>
                            
                            {/* <div className="splide__arrows" /> */}
                            <div className="splide__arrows">
                                <button className="splide__arrow splide__arrow--prev">
                                    <ArrowSliderLeftIcon />
                                    <span className="sr-only">Prev</span>
                                </button>
                                <button className="splide__arrow splide__arrow--next">
                                    <ArrowSliderRightIcon />
                                    <span className="sr-only">Next</span>
                                </button>
                            </div>
                        </Splide>
                            
                        </div>}
                    </div>
                ))}
            </>}
        </>}

    </div>
}

export default CartUpsells